import React, { useState, useEffect } from "react";
import dayjs from "dayjs";
import {
  Button,
  TextField,
  Radio,
  RadioGroup,
  Select,
  Menu,
  MenuItem,
  InputLabel,
} from "@mui/material";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import { DatePicker } from "@mui/x-date-pickers";

import ReCAPTCHA from "react-google-recaptcha";

import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import MuiPhoneNumer from "mui-phone-number";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import PageContainer from "../components/PageContainer";
import apiClient from "../services/apiclient";
import { Link } from "react-router-dom";
import styles from "./loginPage.module.css";
function RegisterPage(props) {
  const [loading, setLoading] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [dateOfBirth, setDateOfBirth] = useState();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const [firstNameError, setFirstNameError] = useState("");
  const [lastNameError, setLastNameError] = useState("");
  const [usernameError, setUsernameError] = useState("");
  const [phoneNumberError, setPhoneNumberError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [dateOfBirthError, setDateOfBirthError] = useState("");

  const [Success, setSuccess] = useState(false);
  const [Captcha, setCaptcha] = useState("");
  const [cardMarginTop, setCardMarginTop] = useState(20);
  const [countdown, setCountdown] = useState(0);
  const [resendCountdown, setResendCountdown] = useState(0);
  console.log(process.env.REACT_APP_SITE_KEY)
  useEffect(() => {
    if (countdown > 0) {
      const countdownTimer = setTimeout(() => {
        setCountdown(countdown - 1);
      }, 1000);

      return () => clearTimeout(countdownTimer);
    }
  }, [countdown]);

  useEffect(() => {
    if (resendCountdown > 0) {
      const resendCountdownTimer = setTimeout(() => {
        setResendCountdown(resendCountdown - 1);
      }, 1000);

      return () => clearTimeout(resendCountdownTimer);
    }
  }, [resendCountdown]);

  const formatTime = (timeInSeconds) => {
    const minutes = Math.floor(timeInSeconds / 60);
    const seconds = timeInSeconds % 60;
    return `${minutes.toString().padStart(2, "0")} : ${seconds
      .toString()
      .padStart(2, "0")}`;
  };
  function handleSubmit(event) {
    let hasError = false;
    const usernameRegex = /^(?=.*[a-zA-Z])[a-zA-Z0-9_-]+$/;
    const nameRegex = /^[a-zA-Z\s-]+$/;
    if (password !== confirmPassword) {
      setPasswordError("wachtwoord komt niet overeen");
      hasError = true;
    }
    console.log(usernameRegex.test(username));
    if (!firstName) {
      setFirstNameError("Voornaam vereist");
      hasError = true;
    }
    if (!lastName) {
      setLastNameError("Achternaam vereist");
      hasError = true;
    }
    if (!email) {
      setEmailError("e-mailadres vereist");
      hasError = true;
    }
    if (!phoneNumber) {
      setPhoneNumberError("telefoonnummer vereist");
      hasError = true;
    }
    if (!username) {
      setUsernameError("gebruikersnaam vereist");
      hasError = true;
    }
    if (!dateOfBirth) {
      setDateOfBirthError("geboortedatum vereist");
      hasError = true;
    }
    if (!password) {
      setPasswordError("wachtwoord vereist");
      hasError = true;
    }
    if (
      !dayjs(dateOfBirth).isValid()
    ) {
      setDateOfBirthError(
        "Ongeldige datum. Gelieve de datum in DD/MM/JJJJ te geven."
      );
      console.log(dateOfBirthError)
      hasError = true;
    }
    if (!usernameRegex.test(username)) {
      setUsernameError(
        "Gebruikersnaam moet minstens één letter bevatten, geen spaties, en alleen letters, cijfers, underscores of streepjes"
      );
      hasError = true;
    }
    if (!nameRegex.test(firstName)) {
      setFirstNameError("Voornaam mag geen speciale tekens bevatten");
      hasError = true;
    }
    if (!nameRegex.test(lastName)) {
      setLastNameError("Achternaam mag geen speciale tekens bevatten");
      hasError = true;
    }
    if (hasError) {
      return;
    }
    setLoading(true);
    apiClient
      .post(`/auth/register`, {
        first_name: firstName,
        last_name: lastName,
        date_of_birth: new Date(dateOfBirth).toLocaleDateString("en-CA", {
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
        }),
        email: email,
        phone_number: `${phoneNumber}`,
        username: username,
        password: password,
        captcha_response_token: Captcha,
      })
      .then(function (response) {
        console.log(response);
        setSuccess(true);
        setCountdown(900);
        setResendCountdown(120);

        setCardMarginTop(20);
        setLoading(false);
      })
      .catch(function (error) {
        setLoading(false);
        console.log(error.response.status);
        if (error.response && error.response.status === 409) {
          if (
            error.response.data.detail ===
            "email-adress or phone number is already registered"
          ) {
            setPhoneNumberError(
              "telefoonnummer of e-mailadres werd reeds geregistreerd"
            );
            setEmailError(
              "telefoonnummer of e-mailadres werd reeds geregistreerd"
            );
          } else if (
            error.response.data.detail === "username is already registered"
          ) {
            setUsernameError("gebruikersnaam is reeds geregistreerd");
          }

          setLoading(false);
          console.log(phoneNumberError);
          console.log("checkpoint");
        } else if (error.response.data.detail === "Invalid captcha") {
          setLoading(false);
          console.log("captcha error");
        }
      });
  }

  function resendEmail() {
    apiClient
      .post(`/auth/resend-email`, {
        email: email,
      })
      .then(function (response) {
        console.log(response);
        setSuccess(true);
        setCountdown(900);
        setResendCountdown(120);
        setCardMarginTop(20);
        setLoading(false);
      });
  }
  return (
    <PageContainer className={styles.background}>
      <Card
        variant="filled"
        sx={{
          p: 2,
          width: 0.9,
          maxWidth: 400,
          mt: cardMarginTop,
          mb: 20,
          position: "relative",
        }}
      >
        <CardContent>
          {Success ? (
            <Box
              sx={{
                //register form
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                textAlign: "center",
                paddingTop: 1,
              }}
            >
              <Button
                startIcon={<ArrowBackIcon />}
                sx={{
                  paddingTop: 1.5,
                  paddingLeft: 1.5,
                  position: "absolute",
                  left: 0,
                  top: 0,

                  alignItems: "Left",
                  display: "flex",
                  color: "black",
                }}
                onClick={() => {
                  setSuccess(false);
                }}
              ></Button>
              Uw account werd successvol geregistreerd. U ontvangt binnen enkele
              minuten een e-mail om uw e-mailadres te bevestigen.
              <strong>{formatTime(countdown)}</strong>
              <Button
                variant="standard"
                sx={{
                  color: "lightgray",
                  display: "flex",
                  flexWrap: "wrap",
                }}
                onClick={resendEmail}
                disabled={resendCountdown > 0}
              >
                {" "}
                E-mail opnieuw verzenden
                {resendCountdown > 0 ? (
                  <span> ( {formatTime(resendCountdown)} )</span>
                ) : null}
              </Button>
            </Box>
          ) : (
            <Box
              sx={{
                //register form
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <h2>Registreren</h2>
              <TextField
                id="firstName"
                label="Voornaam"
                type="text"
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
                varient="outlined"
                sx={{ mb: 2, width: 1 }}
                disabled={loading ? true : false}
                error={firstNameError}
                helperText={firstNameError}
              />
              <TextField
                id="lastName"
                label="Achternaam"
                type="text"
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
                varient="outlined"
                sx={{ mb: 2, width: 1 }}
                disabled={loading ? true : false}
                error={lastNameError}
                helperText={lastNameError}
              />
              <DatePicker
                slotProps={{
                  textField: { id: "bday" },
                }}
                label="geboortedatum"
                format="DD/MM/YYYY"
                views={["year", "month", "day"]}
                defaultValue={dateOfBirth}
                disableFuture
                maxDate={dayjs()}
                sx={{ mb: 2, width: 1 }}
                onChange={(e) => {
                  setDateOfBirth(dayjs(e));
                  console.log(dateOfBirth);
                }}
                disabled={loading}
                error={!!dateOfBirthError}
                helperText={dateOfBirthError}
              ></DatePicker>
              <TextField
                id="email"
                label="e-mailadres"
                type="email"
                value={email}
                onChange={(e) => {
                  setEmail(e.target.value);
                  setEmailError("");
                }}
                fullWidth
                variant="outlined"
                sx={{ mb: 2, width: 1 }}
                disabled={loading}
                error={emailError}
                helperText={emailError}
              />
              <MuiPhoneNumer
                id="phoneNumber"
                defaultCountry="be"
                value={phoneNumber}
                onChange={(phoneNumberValue, country) => {
                  setPhoneNumber(phoneNumberValue);
                  setPhoneNumberError("");
                  console.log(phoneNumberError !== "");
                }}
                variant="outlined"
                fullWidth
                sx={{ mb: 2 }}
                disabled={loading ? true : false}
                error={phoneNumberError}
                helperText={phoneNumberError}
              />
              <TextField
                id="username"
                label="gebruikersnaam"
                type="text"
                value={username}
                onChange={(e) => {
                  setUsername(e.target.value);
                  setUsernameError("");
                }}
                fullWidth
                variant="outlined"
                sx={{ mb: 2, width: 1 }}
                disabled={loading ? true : false}
                error={usernameError}
                helperText={usernameError}
              />
              <TextField
                id="password"
                label="wachtwoord"
                type="password"
                value={password}
                onChange={(e) => {
                  setPassword(e.target.value);
                  setPasswordError("");
                }}
                fullWidth
                variant="outlined"
                sx={{ mb: 2 }}
                disabled={loading ? true : false}
                error={passwordError}
                helperText={passwordError}
              />
              <TextField
                id="confirmPassword"
                label="bevestig wachtwoord"
                type="password"
                value={confirmPassword}
                onChange={(e) => {
                  setConfirmPassword(e.target.value);
                  setPasswordError("");
                }}
                fullWidth
                variant="outlined"
                sx={{ mb: 2 }}
                disabled={loading ? true : false}
                error={passwordError}
                helperText={passwordError}
              />
              <ReCAPTCHA
                sitekey={process.env.REACT_APP_SITE_KEY}
                onChange={(e) => setCaptcha(e)}
              />
              <Box
                sx={{
                  width: 1,
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Button
                  onClick={(e) => handleSubmit(e.target.value)}
                  variant="contained"
                  sx={{
                    backgroundColor: "var(--blue)",
                    "&:focus": { backgroundColor: "var(--blue-hover)" },
                    "&:hover": { backgroundColor: "var(--blue-hover)" },
                    m: 0.2,
                    width: 1,
                  }}
                  disabled={loading || !Captcha}
                >
                  REGISTREREN
                </Button>
                <Box
                  sx={{
                    mt: "1em",
                    "& a": {
                      textDecoration: "underline",
                      color: "lightgray",
                    },
                  }}
                >
                  <Link to="/login">Aanmelden</Link>
                </Box>
              </Box>
            </Box>
          )}
        </CardContent>
      </Card>
    </PageContainer>
  );
}

export default RegisterPage;
