import PageContainer from "../components/PageContainer";
import {
  Box,
} from '@mui/material'
import PostForm from "../components/PostForm";

function CreatePostPage() {
  return (
    <PageContainer>
  
        <PostForm mode="create"/>
    </PageContainer>
  );
}
export default CreatePostPage;
