import { useEffect, useState } from "react";
import PageContainer from "../components/PageContainer";
import apiClient from "../services/apiclient";
import { useParams } from "react-router-dom";
import {
  Box,
  Button,
  Checkbox,
  MenuItem,
  Select,
  TextField,
  FormControl,
  InputLabel,
  Typography,
  Chip,
  Dialog,
  DialogTitle,
  DialogContentText,
  DialogContent,
  FormControlLabel,
} from "@mui/material";
import DownloadIcon from "@mui/icons-material/Download";
import dayjs from "dayjs";
import LoadingScreen from "../components/LoadingScreen";
function ManageVolunteersPage() {
  const { eventURN } = useParams();

  const [volunteers, setVolunteers] = useState([]);
  const [filteredVolunteers, setFilteredVolunteers] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [postNames, setPostNames] = useState([]);
  const [selectedPosts, setSelectedPosts] = useState([]);
  const [selectedDays, setSelectedDays] = useState([]);
  const [days, setDays] = useState([]);

  const [shiftDownloadDialog, setShiftDownloadDialog] = useState(false);
  const [shiftDownloadDaysFilter, setShiftDownloadDaysFilter] = useState([]);
  const [shiftDownloadPostsFilter, setShiftDownloadPostsFilter] = useState([]);
  const [shiftDownloadSplitPerDay, setShiftDownloadSplitPerDay] =
    useState(true);
  const [shiftDownloadSplitPerPost, setShiftDownloadSplitPerPost] =
    useState(false);
  const [exportLoading, setExportLoading] = useState(false);
  const [exportType, setExportType] = useState("xlsx");

  useEffect(() => {
    setExportLoading(true);
    apiClient
      .get(`/manage/${eventURN}/volunteers`)
      .then((response) => {
        setVolunteers(response.data);
        // Extract unique post names from nested shifts
        const uniquePostNames = Array.from(
          new Set(
            response.data.flatMap(
              (volunteer) => volunteer.shifts.map((shift) => shift.post_name) // Adjust 'post_name' to match your data structure
            )
          )
        );
        const uniqueDays = Array.from(
          new Set(
            response.data.flatMap((volunteer) =>
              volunteer.shifts.map((shift) =>
                dayjs(shift.start).format("YYYY-MM-DD")
              )
            )
          )
        ).sort((a, b) => a.localeCompare(b));

        setPostNames(uniquePostNames);
        setDays(uniqueDays);
        setExportLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching volunteers:", error);
      });
  }, [eventURN]);

  useEffect(() => {
    let filterSet = volunteers;

    if (searchValue && searchValue.trim() !== "") {
      filterSet = filterSet.filter((volunteer) => {
        const full_name = `${volunteer.first_name} ${volunteer.last_name}`;
        return full_name.toLowerCase().includes(searchValue.toLowerCase());
      });
    }

    if (selectedPosts.length > 0) {
      filterSet = filterSet.filter((volunteer) => {
        return selectedPosts.some((post) =>
          volunteer.shifts.some((shift) => shift.post_name === post)
        );
      });
    }
    if (selectedDays.length > 0) {
      filterSet = filterSet.filter((volunteer) => {
        return selectedDays.some((day) =>
          volunteer.shifts.some(
            (shift) =>
              dayjs(shift.start).format("YYYY-MM-DD") ===
              dayjs(day).format("YYYY-MM-DD")
          )
        );
      });
    }
    filterSet = filterSet.sort((a, b) => {
      const nameA = `${a.last_name} ${a.first_name}`.toLowerCase();
      const nameB = `${b.last_name} ${b.first_name}`.toLowerCase();
      return nameA.localeCompare(nameB);
    });

    setFilteredVolunteers(filterSet);
  }, [searchValue, volunteers, selectedPosts, selectedDays]);

  useEffect(() => {
    if (!exportLoading) {
      setShiftDownloadDaysFilter(days.map((day) => day));
      setShiftDownloadPostsFilter(postNames.map((post) => post));
    }
  }, [exportLoading]);

  function handleOpenShiftDownloadDialog() {
    setShiftDownloadDialog(true);
    setShiftDownloadDaysFilter(days.map((day) => day));
    setShiftDownloadPostsFilter(postNames.map((post) => post));
  }
  function b64ToBlob(base64Data, contentType) {
    const byteCharacters = atob(base64Data);
    const byteArrays = [];
    for (let offset = 0; offset < byteCharacters.length; offset += 512) {
      const slice = byteCharacters.slice(offset, offset + 512);
      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
      byteArrays.push(new Uint8Array(byteNumbers));
    }
    return new Blob(byteArrays, { type: contentType });
  }

  function download_file(data, filename, contentType) {
    const blob = new Blob([data], { type: contentType }); // Directly create Blob from binary data
    const url = window.URL.createObjectURL(blob); // Create the download URL from Blob
    const a = document.createElement("a");
    a.href = url;
    a.download = filename; // Set the filename for download
    document.body.appendChild(a);
    a.click(); // Trigger the download
    window.URL.revokeObjectURL(url); // Clean up the object URL
    document.body.removeChild(a);
  }

  function downloadShifts() {
    setExportLoading(true);
    apiClient
      .post(
        `/manage/${eventURN}/export/volunteers?&export_type=${exportType}`,
        {
          dates_filter: shiftDownloadDaysFilter,
          split_posts: shiftDownloadSplitPerPost ?? false,
          posts_filter: shiftDownloadPostsFilter,
          split_dates: shiftDownloadSplitPerDay ?? false,
        },
        {
          responseType: "arraybuffer",
        }
      )
      .then((response) => {
        const data = response.data;
        const contentType = response.headers["content-type"];
        let filename;
        if (contentType == "application/zip") {
          filename = `${eventURN}_shifts.zip`;
        } else {
          filename = `${eventURN}_shifts.csv`;
        }
        console.log("Content-Type:", contentType);
        download_file(data, filename, contentType);
        setExportLoading(false);
        setShiftDownloadDialog(false);
      })
      .catch((error) => {
        console.error("Dowload failed:", error);
        setExportLoading(false);
        setShiftDownloadDialog(false);
      });
  }
  function downloadRegistrationResponses() {
    let filename = `${eventURN}_registration_responses.csv`;
    apiClient
      .get(`/manage/${eventURN}/export/registration-responses`)
      .then((response) => {
        const data = response.data;
        download_file(data, filename);
      })
      .catch((error) => {
        console.error("Dowload failed:", error);
      });
  }
  return (
    <PageContainer>
      <h2>Vrijwilligers</h2>
      <Box
        sx={{
          width: "100%",
          display: "flex",

          alignItems: "center",
          padding: "2rem 0",
          gap: "2rem",
          justifyContent: "center",
          flexDirection: "column",
        }}
      >
        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: { md: "1fr 1fr" },
            gap: "1rem",
          }}
        >
          <Button
            variant="contained"
            startIcon={<DownloadIcon />}
            onClick={downloadRegistrationResponses}
          >
            formulierantwoorden
          </Button>
          <Button
            variant="contained"
            startIcon={<DownloadIcon />}
            onClick={() => handleOpenShiftDownloadDialog()}
          >
            shiften
          </Button>
        </Box>
        <Box
          sx={{
            width: "80%",
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap",
            alignItems: "start",
            justifyContent: "center",
            flexGrow: "1",
            gap: "1rem",
          }}
        >
          <TextField

            label="Zoeken"
            value={searchValue}
            onChange={(e) => setSearchValue(e.target.value)}
          />
          <FormControl sx={{ minWidth: "15rem", maxWidth: "20rem"}}>
            <InputLabel id="post-filter-label">Filter op post</InputLabel>
            <Select
              labelId="post-filter-label" // Associates the label with this Select
              multiple
              label="filter op post"
              fullWidth
              value={selectedPosts} // Array of selected posts
              onChange={(e) => setSelectedPosts(e.target.value)} // Handle updates on selection change
              renderValue={(selected) => (
                <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                  {selected.map((value) => (
                    <Chip key={value} label={value} />
                  ))}
                </Box>
              )}
            >
              {postNames.map((postName, index) => (
                <MenuItem key={index} value={postName}>
                  <Checkbox checked={selectedPosts.includes(postName)} />
                  {postName}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl sx={{ minWidth: "15rem", maxWidth: "20rem" }}>
            <InputLabel id="day-filter-label">Filter op dag</InputLabel>
            <Select
              labelId="day-filter-label" // Associates the label with this Select
              multiple
              fullWidth
              label="filter op dag"
              value={selectedDays} // Array of selected days
              onChange={(e) => setSelectedDays(e.target.value)} // Handle updates on selection change
              renderValue={(selected) => (
                <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                  {selected.map((value) => (
                    <Chip key={value} label={value} />
                  ))}
                </Box>
              )}
            >
              {days.map((day, index) => (
                <MenuItem key={index} value={day}>
                  <Checkbox checked={selectedDays.includes(day)} />
                  {day}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
        <Box
          sx={{
            width: "80%",
            display: "grid",
            gridAutoRows: "1fr",
            gap: ".3rem",
          }}
        >
          {filteredVolunteers.map((volunteer) => {
            const registered_days = Array.from(
              new Set(
                volunteer.shifts.map((shift) =>
                  dayjs(shift.start).format("DD-MM")
                )
              )
            ).sort((a, b) => a.localeCompare(b));
            const registered_posts = Array.from(
              new Set(volunteer.shifts.map((shift) => shift.post_name))
            );

            return (
              <Box
                sx={{
                  display: "grid",
                  gridTemplateColumns: "1fr 1fr",
                  columnGap: ".3rem",
                  rowGap: ".2rem",
                  padding: "1rem",
                  border: "1px solid #ccc",
                  borderRadius: "1rem",
                  justifyContent: "start",
                  alignItems: "center",
                  "&:hover": {
                    backgroundColor: "#F1F1F1FF",
                  },
                }}
                key={volunteer.id}
              >
                <p>
                  {volunteer.last_name} {volunteer.first_name}
                </p>
                <Box
                  sx={{
                    gridRow: 2,
                    gridColumn: "1/3",
                    display: "flex",
                    flexWrap: "wrap",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    columnGap: "1rem",
                    rowGap: ".5rem",
                  }}
                >
                  {registered_days.map((day) => {
                    return (
                      <Box
                        sx={{
                          backgroundColor: "var(--green)",
                          color: "white",
                          minWidth: "3rem",
                          textAlign: "center",
                          padding: ".2rem 1em",
                          borderRadius: "1rem",
                          cursor: "pointer",
                        }}
                      >
                        {day}
                      </Box>
                    );
                  })}
                </Box>
                <Box
                  sx={{
                    gridRow: 3,
                    gridColumn: "1/3",
                    display: "flex",
                    flexWrap: "wrap",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    columnGap: "1rem",
                    rowGap: ".5rem",
                  }}
                >
                  {registered_posts.map((post) => {
                    return (
                      <Box
                        sx={{
                          backgroundColor: "var(--orange)",
                          color: "white",
                          minWidth: "3rem",
                          textAlign: "center",
                          padding: ".2rem 1em",
                          borderRadius: "1rem",
                          cursor: "pointer",
                        }}
                      >
                        {post}
                      </Box>
                    );
                  })}
                </Box>
                <Typography
                  sx={{
                    gridRow: 4,
                    gridColumn: "1/3",
                    color: "lightgray",
                    textAlign: "right",
                  }}
                  variant="p"
                >
                  {volunteer.uuid}
                </Typography>
              </Box>
            );
          })}
        </Box>
      </Box>
      <Dialog
        open={shiftDownloadDialog}
        onClose={() => setShiftDownloadDialog(false)}
        fullWidth={true}
      >
        <DialogTitle>Shiften exporten</DialogTitle>
        {exportLoading ? (
          <DialogContent
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <LoadingScreen />
          </DialogContent>
        ) : (
          <DialogContent>
            <Box
              sx={{
                display: "grid",
                gap: "1rem",
              }}
            >
              <DialogContentText
                sx={{
                  gridRow: { sx: "1/2", md: "1/2" },
                  gridColumn: { sx: "1/2", md: "1/2" },
                }}
              >
                filter op dagen:
              </DialogContentText>
              <DialogContentText
                sx={{
                  gridRow: { sx: "3/4", md: "1/2" },
                  gridColumn: { sx: "1/2", md: "2/3" },
                }}
              >
                filter op posten:
              </DialogContentText>

              <Select
                sx={{
                  gridRow: "2/3",
                  gridColumn: "1/2",
                }}
                labelId="day-filter-label"
                multiple
                label="filter op dag"
                fullWidth={true}
                value={shiftDownloadDaysFilter} // Array of selected days
                onChange={(e) => setShiftDownloadDaysFilter(e.target.value)} // Handle updates on selection change
                renderValue={(selected) => {
                  if (
                    selected.length === 0 ||
                    selected.length === days.length
                  ) {
                    return <Typography>alle</Typography>;
                  } else {
                    return (
                      <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                        {selected.map((value) => (
                          <Chip key={value} label={value} />
                        ))}
                      </Box>
                    );
                  }
                }}
              >
                {days.map((day, index) => (
                  <MenuItem key={index} value={day}>
                    <Checkbox checked={shiftDownloadDaysFilter.includes(day)} />
                    {day}
                  </MenuItem>
                ))}
              </Select>

              <Select
                sx={{
                  gridRow: { sx: "3/4", md: "2/3" },
                  gridColumn: { sx: "1/2", md: "2/3" },
                }}
                labelId="post-filter-label"
                multiple
                label="filter op post"
                fullWidth={true}
                value={shiftDownloadPostsFilter}
                onChange={(e) => setShiftDownloadPostsFilter(e.target.value)}
                renderValue={(selected) => {
                  if (
                    selected.length === 0 ||
                    selected.length === postNames.length
                  ) {
                    return "alle";
                  } else {
                    return (
                      <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                        {selected.map((value) => (
                          <Chip key={value} label={value} />
                        ))}
                      </Box>
                    );
                  }
                }}
              >
                {postNames.map((postName, index) => (
                  <MenuItem key={index} value={postName}>
                    <Checkbox
                      checked={shiftDownloadPostsFilter.includes(postName)}
                    />
                    {postName}
                  </MenuItem>
                ))}
              </Select>
              <FormControl>
                <FormControlLabel
                  control={
                    <Checkbox
                      value={shiftDownloadSplitPerDay}
                      onChange={(e) =>
                        setShiftDownloadSplitPerDay(e.target.checked)
                      }
                      defaultChecked
                    />
                  }
                  label="Splitsen per dag"
                />

                <FormControlLabel
                  control={
                    <Checkbox
                      value={shiftDownloadSplitPerPost}
                      onChange={(e) => {
                        console.log(e.target.checked)
                        setShiftDownloadSplitPerPost(e.target.checked)
                      }
                      }
                    />
                  }
                  label="Splitsen per post"
                />
              </FormControl>
              <DialogContentText
                sx={{
                  gridRow: { md: "4/5" },
                  gridColumn: "1/2",
                }}
              >
                export-type
              </DialogContentText>
              <Select
                value={exportType}
                onChange={(e) => setExportType(e.target.value)}
                sx={{
                  gridRow: { md: "5/6" },
                  gridColumn: "1/2",
                }}
              >
                <MenuItem value="csv">CSV</MenuItem>
                <MenuItem value="xlsx">Excel</MenuItem>
              </Select>
              <Button
                sx={{
                  gridRow: { sx: "5/6", md: "6/7" },
                  gridColumn: { sx: "1/2", md: "1/3" },
                }}
                fullWidth
                variant="contained"
                onClick={() => downloadShifts()}
              >
                exporteren
              </Button>
            </Box>
          </DialogContent>
        )}
      </Dialog>
    </PageContainer>
  );
}

export default ManageVolunteersPage;
