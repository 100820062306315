import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import PageContainer from "../components/PageContainer";
import InnerBox from "../components/InnerBox";
import {
  Box,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Button,
  IconButton,
} from "@mui/material";
import { ArrowDropDownIcon } from "@mui/x-date-pickers";
import DeleteIcon from "@mui/icons-material/Delete";

import apiClient from "../services/apiclient";
import dayjs from "dayjs";
import transformKeys from "../utils/keyUtils";

function ShiftManagementPage() {
  const { eventURN } = useParams();
  const [timeSlots, setTimeSlots] = useState([]);
  const [shifts, setShifts] = useState([]);
  const [deleteTrigger, setDeleteTrigger] = useState(0);

  useEffect(() => {
    apiClient
      .get(`/manage/${eventURN}/time_slots/data`)

      .then((response) => {
        console.log(response.data);
        let timeSlotData = transformKeys(response.data);
        setTimeSlots(sortTimeSlots(timeSlotData));
      });
    apiClient.get(`/manage/${eventURN}/shifts/data`).then((response) => {
      console.log(response.data);
      let shiftData = transformKeys(response.data);
      setShifts(shiftData);
    });
  }, [eventURN, deleteTrigger]);

  const sortTimeSlots = (data) => {
    const sortedKeys = Object.keys(data).sort(
      (a, b) => dayjs(a).valueOf() - dayjs(b).valueOf()
    );

    const sortedData = {};
    sortedKeys.forEach((key) => {
      sortedData[key] = data[key].sort((a, b) => {
        const startComparison =
          dayjs(a.start).valueOf() - dayjs(b.start).valueOf(); // Compare start times
        if (startComparison !== 0) {
          return startComparison; // If start times are different, use this comparison
        }
        // If start times are the same, compare end times
        return dayjs(a.end).valueOf() - dayjs(b.end).valueOf();
      });
    });
    return sortedData;
  };

  const deleteTimeSlot = (timeSlotId) => {
    apiClient
      .delete(`/manage/${eventURN}/time_slots/${timeSlotId}`)
      .then((response) => {
        console.log(response.data);
        setDeleteTrigger((prevState) => prevState + 1);
      });
  };
  return (
    <PageContainer>
      <h1>Shiften</h1>
      <Box width="100%">
        {Object.keys(timeSlots).map((date, index) => {
          return (
            <Accordion
              key={date}
              disableGutters
              square={true}
              elevation={0}
              slotProps={{ heading: { component: "h4" } }}
              sx={{
                width: "100%",
                borderBottom: "1px solid lightgray",
              }}
            >
              <AccordionSummary
                expandIcon={<ArrowDropDownIcon />}
                aria-controls={`${date}-content`}
                id={`${date}-header`}
              >
                <Box
                  sx={{
                    display: "grid",
                    gridTemplateColumns: "2fr 2fr auto",
                    alignItems: "center",

                    width: "100%",
                  }}
                >
                  <Typography variant="p" sx={{ fontWeight: "bold" }}>
                    {dayjs(date).format("dddd")}
                  </Typography>
                  <Typography variant="p" sx={{ fontWeight: "bold" }}>
                    {dayjs(date).format("DD-MM-YYYY")}
                  </Typography>
                  <Typography
                    variant="p"
                    sx={{
                      fontWeigth: "bold",
                      color: "white",
                      backgroundColor: "var(--blue)",
                      borderRadius: "2rem",
                      padding: ".5rem",
                    }}
                  >
                    {timeSlots[date].length} shiften
                  </Typography>
                </Box>
              </AccordionSummary>

              <AccordionDetails>
                {timeSlots[date].map((timeSlot, index) => {
                  let presentShifts = shifts.filter((shift) => {
                    return shift.timeSlotId === timeSlot.id;
                  });
                  return (
                    <Accordion>
                      <AccordionSummary expandIcon={null}>
                        <Box
                          sx={{
                            display: "grid",
                            gridTemplateColumns: "1fr 1fr 1fr 5fr",
                            justifyContent: "space-between",
                            

                            alignItems: "center",
                            width: "100%",
                            marginTop: "1rem",
                          }}
                        >
                          <Typography variant="p" sx={{
                            justifySelf: "start"
                          }}>
                            {dayjs(timeSlot.start).format("HH:mm")}
                          </Typography>
                          <Typography sx={{
                            justifySelf: "center"
                          }}>-</Typography>
                          <Typography variant="p">
                            {dayjs(timeSlot.end).format("HH:mm")}
                          </Typography>
                          <Typography
                            variant="p"
                            sx={{
                              width: "max-content",
                              marginLeft: "1rem",
                              color: "white",
                              justifySelf: "end",
                              backgroundColor:
                                presentShifts.length > 0
                                  ? "var(--yellow)"
                                  : "var(--red)",
                              borderRadius: "2rem",
                              padding: ".5rem",
                            }}
                          >
                            {presentShifts.length}{" "}
                                  {presentShifts.length != 1
                                    ? "posten"
                                    : "post"}
                          </Typography>
                          {presentShifts.length == 0 ? (
                            <IconButton
                              sx={{
                                backgroundColor: "#BABABABA",
                                borderRadius: ".5rem",
                                width: "fit-content",
                                "&:hover": {
                                  backgroundColor: "var(--red)",
                                },
                              }}
                              onClick={() => deleteTimeSlot(timeSlot.id)}
                            >
                              <DeleteIcon sx={{ color: "white" }} />
                            </IconButton>
                          ) : null}
                        </Box>
                      </AccordionSummary>
                      {presentShifts && (
                        <AccordionDetails>
                          {presentShifts.map((shift) => {
                            console.log(shift.maxVolunteers);
                            let backgroundColor;
                            if (!shift.maxVolunteers) {
                              backgroundColor = "var(--teal)";
                            } else if (
                              shift.totalRegistrations / shift.maxVolunteers >
                                0.75 &&
                              shift.totalRegistrations / shift.maxVolunteers < 1
                            ) {
                              backgroundColor = "var(--orange)";
                            } else if (
                              shift.totalRegistrations >= shift.maxVolunteers
                            ) {
                              backgroundColor = "var(--green)";
                            } else {
                              backgroundColor = "var(--gray)";
                            }
                            return (
                              <Box
                                sx={{
                                  display: "grid",
                                  gridTemplateColumns: "1fr auto 1fr",
                                  paddingRight: "1rem",
                                  marginTop: "1rem",
                                }}
                              >
                                <Typography variant="p">
                                  {shift.postName}
                                </Typography>
                                <Typography
                                  variant="p"
                                  sx={{
                                    width: "max-content",
                                    borderRadius: "2rem",
                                    backgroundColor: backgroundColor,
                                    color: "white",
                                    padding: ".5rem",
                                    textAlign: "center",
                                  }}
                                >
                                  {shift.totalRegistrations}{" "}
                                  {shift.totalRegistrations != 1
                                    ? "registraties"
                                    : "registratie"}
                                </Typography>
                              </Box>
                            );
                          })}
                        </AccordionDetails>
                      )}
                    </Accordion>
                  );
                })}
              </AccordionDetails>
            </Accordion>
          );
        })}
      </Box>
    </PageContainer>
  );
}

export default ShiftManagementPage;
