import React from "react";
import { motion } from "framer-motion";
import { Box } from "@mui/material";

function LoadingScreen() {
  return (
    <Box sx={{
      padding: "2rem",
      overflow: "hidden",
    }}>
      <Box
        sx={{
          width: "3em",
          height: "3em",
          backgroundColor: "white",
          borderRadius: "50%",
          display: "flex",
          justifyContent: "center",
          color: "#003de0ff",
          textAlign: "center",
          alignItems: "center",
        }}
        component={motion.div}
        animate={{
          scale: [2, 1, 1, 1, 2],
          rotate: [0, 90, -90, 360],
          borderRadius: ["20%", "50%", "50%", "50%", "20%"],
        }}
        transition={{
          duration: 2.5, // Duration of the animation cycle
          ease: "easeInOut", // Easing function
          repeat: Infinity, // Repeat infinitely
          repeatType: "loop", // Loop the animation
          repeatDelay: 0.5, // Pause for 0.5 seconds at the end of each cycle
        }}
      >
        <h1>V</h1>
      </Box>
    </Box>
  );
}

export default LoadingScreen;
