import './App.css'

import { useAxiosInterceptor } from './services/apiclient';
import Navbar from './components/Navbar';
import LoginPage from './pages/loginPage';
import RegisterPage from './pages/RegisterPage';
import DashboardPage from './pages/DashboardPage';
import CreateEventPage from './pages/CreateEventPage';
import ConfirmEmailPage from './pages/ConfirmEmailPage';
import LogoutPage from './pages/LogoutPage';
import { LocalizationProvider } from "@mui/x-date-pickers";
import { nlNL } from '@mui/x-date-pickers/locales';
import { createTheme, ThemeProvider } from '@mui/material';
// eslint-disable-next-line
import { locale } from 'dayjs/locale/nl-be';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import dayjs from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { BrowserRouter as Router, Routes, Route, useLocation } from "react-router-dom";
import { AnimatePresence } from "framer-motion"
import LoadingScreen from './components/LoadingScreen';
import ConfirmationScreen from './components/ConfirmationScreen';
import { UserProvider } from './contexts/UserContext';
import { EventProvider } from "./contexts/EventContext"
import PrivateRoutes from './utils/PrivateRoutes';
import EventNavbar from './components/EventNavbar';
import EventDashboard from './pages/EventDashboardPage';
import SetupEventPage from './pages/SetupEventPage';
import PostsPage from "./pages/PostsPage";
import EventsPage from './pages/EventsPage';
import HomePage from './pages/HomePage';
import ProfilePage from './pages/ProfilePage';
import CreatePostPage from './pages/CreatePostPage';
import UpdatePostPage from './pages/UpdatePostPage';
import RegistrationFieldsSelector from './components/setupEventComponents/RegistrationFieldsSelector';
import RegistrationFieldsPage from './pages/RegistrationFieldsPage';
import EventDetailsPage from './pages/EventDetailsPage';
import VolunteerRegistrationPage from "./pages/VolunteerRegistrationPage";
import PageContainer from './components/PageContainer';
import ManageVolunteersPage from './pages/ManageVolunteersPage';
import ResetPasswordPage from './pages/ResetPasswordPage';
import CommunicationManagementPage from './pages/CommunicationManagementPage';
import ShiftManagementPage from './pages/ShiftManagementPage';
import CheckInPage from './pages/CheckInPage';
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.locale('nl-be');
function App() {
  useAxiosInterceptor();
  return (
    <UserProvider>
      <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="nl-be" localeText={nlNL.components.MuiLocalizationProvider.defaultProps.localeText}>
        <ThemeProvider theme={theme}>
          <Router>
            <AnimatedRoutes />
          </Router>
        </ThemeProvider>

      </LocalizationProvider >
    </UserProvider>
  );
}

const theme = createTheme({
  palette: {
    primary: {
      main: '#1f50da',
    },
    secondary: {
      main: '#f44336',
    },
  },
});

function AnimatedRoutes() {
  const location = useLocation()
  return (
    <AnimatePresence mode="wait">
      <Routes location={location} key={location.pathname}>
        <Route path="/" element={<Navbar />}>
          <Route index element={<HomePage />} />
          <Route path="events">
            <Route path="" element={<EventsPage />} />
            <Route path=":eventURN">
              <Route path="" element={<EventDetailsPage />} />
              <Route element={<PrivateRoutes />}>
                <Route path="register" element={<VolunteerRegistrationPage />} />
              </Route>
            </Route>
          </Route>
          <Route path="login" element={<LoginPage />} />
          <Route path="register" element={<RegisterPage />} />
          <Route path="confirm-email" element={<ConfirmEmailPage />} />
          <Route path="reset-password" element={<ResetPasswordPage />}/>
          
          <Route element={<PrivateRoutes />}>
            <Route path="profile" element={<ProfilePage/>} />
            <Route path="dashboard" element={<DashboardPage />} />
            <Route path="events/create" element={<CreateEventPage />} />
            <Route path='loading' element={<LoadingScreen/>}/>
            <Route path="confirmation" element={<ConfirmationScreen/>} />
            <Route path="manage/:eventURN/*" element={
              <EventProvider>
                <EventNavbar />
              </EventProvider>}>
              <Route path="" element={<EventDashboard />} />
              <Route path="volunteers" element={<ManageVolunteersPage/>}/>
              <Route path="setup" element={<SetupEventPage />} />
              <Route path="posts">
                <Route path='' element={<PostsPage />} />
                <Route path='create' element={<CreatePostPage />} />
                <Route path=':id' element={<UpdatePostPage />} />

              </Route>
              <Route path="shifts" element={<ShiftManagementPage />} />
              <Route path="registration-form" element={<RegistrationFieldsPage />} />
              <Route path="communication" element={<CommunicationManagementPage/>}/>
              <Route path="check-in" element={<CheckInPage />} />
            </Route>
          </Route>
          <Route path="logout" element={<LogoutPage />} />
        </Route>
      </Routes>
    </AnimatePresence>
  )
}
export default App;
