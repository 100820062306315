import React from "react";
import PageContainer from "../components/PageContainer";
import {
  Box,
  FormControlLabel,
  Switch,
  List,
  ListItem,
  Button,
  IconButton,
  Fab,
  Typography,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import { useState, useEffect } from "react";
import { useParams, Link, useNavigate } from "react-router-dom";
import InnerBox from "../components/InnerBox";
import apiClient from "../services/apiclient";

function PostsPage() {
  const [posts, setposts] = useState([]);
  const { eventURN } = useParams();
  const navigate = useNavigate();
  useEffect(() => {
    const fetchData = async () => {
      apiClient.get(`/manage/${eventURN}/posts/data`).then((response) => {
        console.log(response.data);
        setposts(response.data);
      });
    };
    fetchData();
  }, []);

  console.log(posts);
  const sortShifts = (shifts) => {
    const sortedDates = Object.keys(shifts).sort(
      (a, b) => new Date(a) - new Date(b)
    );

    const sortedShifts = {};

    sortedDates.forEach((date) => {
      sortedShifts[date] = shifts[date].sort((a, b) => {
        const startCompare = new Date(a.start) - new Date(b.start);
        if (startCompare !== 0) {
          return startCompare; // If different, return the comparison
        }
        return new Date(a.end) - new Date(b.end); // Secondary sort by endDateTime
      });
    });
    return sortedShifts;
  };

  const formatISODate = (isoString) => {
    const date = new Date(isoString);
    const day = date.toLocaleString("nl-BE", { day: "2-digit" });
    const month = date.toLocaleString("nl-BE", { month: "2-digit" });
    const time = date.toLocaleString("nl-BE", {
      hour: "2-digit",
      minute: "2-digit",
    });
    return (
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: "1fr 1fr",
          alignItems: "center",
          "& p": {
            width: "100%",
          }
        }}
      >
        <Typography variant="p" sx={{
          textAlign: "left"}}>{day}/{month}</Typography>
        <Typography variant="p" sx={{
          textAlign: "right"}}>{time}</Typography>
      </Box>
    );
  };
  return (
    <PageContainer noFullWidth>
      <Box>
        <h1
          style={{
            marginLeft: "1rem",
            fontSize: "2.5rem",
          }}
        >
          posten
        </h1>
        <Box
          sx={{
            display: "flex",
            width: {
              xs: "calc(100vw - 2rem)",
              md: "calc(100vw - 16rem - 4rem)",
            },
            justifyContent: {
              xs: "center",
              md: "flex-start",
            },
            flexWrap: "wrap",

            "& h2": {
              m: "0.4em 0em",
              textAlign: "left",
            },
            "& p": {
              color: "gray",
              fontSize: "1rem",
            },
            margin: "auto",
          }}
        >
          {posts.map((post) => {
            post.shifts = sortShifts(post.shifts);
            console.log(post.shifts);
            return (
              <InnerBox
                key={post.id}
                maxWidth="20rem"
                width="100%"
                position="relative"
              >
                <Box
                  height="100%"
                  sx={{
                    position: "relative",
                    padding: "-2rem",
                    width: "100%",
                    height: "100%",
                    "&:hover .edit-btn": {
                      visibility: "visible",
                      opacity: 1,
                      transition: ".3s",
                    },
                  }}
                >
                  <IconButton
                    className="edit-btn"
                    sx={{
                      position: "absolute",
                      top: "-2rem",
                      right: "-2rem",
                      width: "3.5rem",
                      height: "3.5rem",
                      backgroundColor: "var(--blue)",
                      visibility: "hidden",
                      opacity: 0,
                      transitionDuration: ".3s",
                      "&:hover": {
                        backgroundColor: "var(--blue-hover)",
                        visibility: "visible",
                      },
                    }}
                    onClick={() => {
                      console.log("onClick");
                      navigate(`${post.id}`);
                    }}
                  >
                    <EditIcon htmlColor="white" />
                  </IconButton>
                  <Box
                    sx={{
                      height: "20rem",
                      width: "100%",
                      alignItems: "center",
                      flexDirection: "column",
                      display: "flex",
                    }}
                  >
                    <h2>{post.name}</h2>
                    <p>{post.description}</p>
                    {post.age_restriction > 0 && (
                      <Box
                        sx={{
                          backgroundColor: "var(--green)",
                          color: "white",
                          padding: ".2em 1em",
                          borderRadius: "1em",
                          margin: ".5em 0em",
                          width: "fit-content",
                        }}
                      >
                        leeftijdsbeperking: {post.age_restriction}
                      </Box>
                    )}
                    {post.code && (
                      <Box
                        sx={{
                          backgroundColor: "var(--yellow)",
                          color: "white",
                          padding: ".2em 1em",
                          borderRadius: "1em",
                          margin: ".5em 0em",
                          width: "fit-content",
                        }}
                      >
                        code: {post.code}
                      </Box>
                    )}
                  </Box>

                  <Box
                    sx={{
                      width: "100%",
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <FormControlLabel
                      control={<Switch />}
                      sx={{ justifyContent: "center", pb: "1em" }}
                      label="zichtbaar"
                    />

                    <p>
                      <strong>shiften</strong>
                    </p>
                    <List
                      sx={{
                        display: "grid",
                        width: "100%",
                        alignItems: "center",
                        marginTop: "-.5em",
                        borderTop: "lightgray solid 1px",
                      }}
                    >
                      {Object.entries(post.shifts).map(([date, shiftsArray]) =>
                        shiftsArray.map((shift, index) => {
                          console.log(formatISODate(shift.start));
                          return (
                            <ListItem
                              key={`${date}-${index}`} // Add a unique key combining date and index
                              sx={{
                                display: "grid",
                                gridTemplateColumns: "1fr 1fr 1fr",
                                justifyContent: "space-around",
                                textAlign: "center",
                                width: "auto",

                                "& span": {
                                  m: 0,
                                  color: "gray",
                                },
                              }}
                            >
                              {formatISODate(shift.start)}
                              <Typography
                                variant="p"
                                sx={{
                                  textAlign: "center",
                                }}
                              >
                                -
                              </Typography>
                              {formatISODate(shift.end)}
                            </ListItem>
                          );
                        })
                      )}
                    </List>
                  </Box>
                </Box>
              </InnerBox>
            );
          })}
        </Box>
      </Box>
      <Fab
        sx={{
          position: "fixed",
          right: "2rem",
          bottom: "2rem",
        }}
        color="primary"
        aria-label="add"
        onClick={() => navigate("create")}
      >
        <AddIcon />
      </Fab>
    </PageContainer>
  );
}
export default PostsPage;
