import React, { useContext, useEffect, useState } from "react";
import { Button, TextField, Typography, IconButton } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Box from "@mui/material/Box";
import apiClient from "../services/apiclient";
import PageContainer from "../components/PageContainer";
import { Link, useNavigate } from "react-router-dom";
import styles from "./loginPage.module.css";
import { UserContext } from "../contexts/UserContext";
import AlertPopup from "../components/AlertPopup";

function LoginPage(props) {
  const { isLoggedIn, setIsLoggedIn } = useContext(UserContext);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(null);
  const [forgotPassword, setForgotPassword] = useState(false);
  const [recoveryMail, setRecoveryMail] = useState("");
  const [recoveryMailError, setRecoveryMailError] = useState("");
  const [alert, setAlert] = useState({
    open: false,
    message: "",
    type: "",
  });

  const navigate = useNavigate();

  async function handleSubmit(event) {
    event.preventDefault();
    const body = new FormData();
    body.append("username", username);
    body.append("password", password);

    try {
      const response = await apiClient.post("/auth/token", body);

      // Assuming the response contains the tokens
      const { access_token, refresh_token } = response.data;

      // Save tokens to local storage or cookies
      localStorage.setItem("access_token", access_token);
      localStorage.setItem("refresh_token", refresh_token);

      // Redirect to a protected route after login
      setIsLoggedIn(true);
      navigate("/dashboard"); // Or wherever you want to redirect
    } catch (error) {
      // Handle error (e.g., show error message)
      setError("Invalid username or password");
      console.error("Login failed:", error);
    }
  }

  useEffect(() => {
    setRecoveryMail("");
  }, [forgotPassword]);

  useEffect(() => {
    setRecoveryMailError("");
    console.log(recoveryMail);
  }, [recoveryMail]);

  function handleForgotPassword() {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(recoveryMail)) {
      setRecoveryMailError("geef een geldig e-mailadres");
      return;
    }
    apiClient
      .post("/auth/reset-password/request", {
        email: recoveryMail,
      })
      .then((response) => {
        setAlert({
          open: true,
          message:
            "Er is een e-mail verstuurd met instructies om het wachtwoord te resetten",
          severity: "success",
        });
        setForgotPassword(false);
      })
      .catch((error) => {
        setAlert({
          open: true,
          message: "Er is een fout opgetreden bij het verzenden van het e-mail",
          severity: "error",
        });
        console.error("Error sending recovery email:", error);
      });
  }
  return (
    <PageContainer className={styles.background}>
      <Card variant="filled" sx={{ p: 2, width: 0.9, maxWidth: 400 }}>
        <CardContent>
          {!forgotPassword && (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <h2>Login</h2>
              {error && <p style={{ color: "red" }}>{error}</p>}
              <TextField
                id="username"
                label="gebruikersnaam"
                type="text"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                fullWidth
                variant="outlined"
                sx={{ mb: 2 }}
              />
              <TextField
                id="password"
                label="Password"
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                fullWidth
                variant="outlined"
                sx={{ mb: 2 }}
              />
              <Box
                sx={{
                  width: 1,
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  mb: 2,
                  width: 1,
                  justifyContent: "space-between",
                }}
              >
                <Button
                  variant="contained"
                  sx={{
                    backgroundColor: "var(--blue)",
                    "&:focus": { backgroundColor: "var(--blue)" },
                    "&:hover": { backgroundColor: "var(--blue)" },
                    m: 0.2,
                    width: 1,
                  }}
                  onClick={handleSubmit}
                >
                  AANMELDEN
                </Button>
                <Box>
                  <Typography
                    onClick={() => setForgotPassword(true)}
                    sx={{
                      marginTop: "1rem",
                      textTransform: "none",
                      color: "lightgray",
                      fontSize: "1rem",
                      textAlign: "center",
                      "&:hover": {
                        backgroundColor: "none",
                        textDecoration: "underline",
                      },
                      cursor: "pointer",
                    }}
                  >
                    Wachtwoord vergeten? Herstel hier
                  </Typography>
                  
                    <Typography
                      sx={{
                        textTransform: "none",
                        color: "lightgray",
                        fontSize: "1rem",
                        textAlign: "center",
                        textDecoration: "none",
                        "&:hover": {
                          backgroundColor: "none",
                          textDecoration: "underline",
                        },
                        cursor: "pointer",
                      }}
                      onClick={() => navigate("/register")}
                    >
                      Nog geen account? Registreer je hier!
                    </Typography>
                </Box>
              </Box>
            </Box>
          )}
          {forgotPassword && (
            <Box
              sx={{
                position: "relative",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                gap: "1rem",
                // margin: "-1.5rem",
                textAlign: "center",
              }}
            >
              <IconButton
                sx={{ position: "absolute", left: 0, top: 0 }}
                onClick={() => setForgotPassword(false)}
              >
                <ArrowBackIcon />
              </IconButton>
              <h2>Wachtwoord vergeten</h2>
              <Typography variant="p">
                Vul uw e-mailadres in om een herstel-link te ontvangen.
              </Typography>
              <TextField
                value={recoveryMail}
                onChange={(e) => setRecoveryMail(e.target.value)}
                error={!!recoveryMailError}
                helperText={recoveryMailError}
                id="email"
                label="E-mailadres"
                type="email"
                fullWidth
              />
              <Button onClick={handleForgotPassword} variant="contained">
                herstel wachtwoord
              </Button>
            </Box>
          )}
        </CardContent>
      </Card>
      <AlertPopup alert={alert} setAlert={setAlert} />
    </PageContainer>
  );
}

export default LoginPage;
